import React from 'react'

const ProfessionalismIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    style={{ fill: 'none' }}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 9.12671C3 8.59628 3.21071 8.08757 3.58579 7.7125C3.96086 7.33742 4.46957 7.12671 5 7.12671H19C19.5304 7.12671 20.0391 7.33742 20.4142 7.7125C20.7893 8.08757 21 8.59628 21 9.12671V18.1267C21 18.6571 20.7893 19.1658 20.4142 19.5409C20.0391 19.916 19.5304 20.1267 19 20.1267H5C4.46957 20.1267 3.96086 19.916 3.58579 19.5409C3.21071 19.1658 3 18.6571 3 18.1267V9.12671Z"
      stroke="#B6BEC7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 7.12671V5.12671C8 4.59628 8.21071 4.08757 8.58579 3.7125C8.96086 3.33742 9.46957 3.12671 10 3.12671H14C14.5304 3.12671 15.0391 3.33742 15.4142 3.7125C15.7893 4.08757 16 4.59628 16 5.12671V7.12671"
      stroke="#B6BEC7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 12.1267V12.1367"
      stroke="#B6BEC7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 13.1267C5.79158 14.5334 8.87403 15.2661 12 15.2661C15.126 15.2661 18.2084 14.5334 21 13.1267"
      stroke="#B6BEC7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ProfessionalismIcon
