import React from 'react'

const ClientCentricityIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    style={{ fill: 'none' }}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.5013 12.6984L12.0013 20.1264L4.50128 12.6984C4.00658 12.217 3.61692 11.6384 3.35683 10.999C3.09673 10.3597 2.97184 9.67335 2.99002 8.98333C3.00819 8.29331 3.16904 7.61453 3.46244 6.98973C3.75583 6.36494 4.17541 5.80765 4.69476 5.35298C5.21411 4.8983 5.82198 4.55609 6.48009 4.34788C7.1382 4.13967 7.83228 4.06998 8.51865 4.1432C9.20501 4.21642 9.86878 4.43095 10.4682 4.7733C11.0675 5.11565 11.5895 5.5784 12.0013 6.1324C12.4148 5.58242 12.9374 5.12372 13.5364 4.785C14.1353 4.44629 14.7978 4.23485 15.4822 4.16392C16.1666 4.09299 16.8584 4.1641 17.5141 4.37279C18.1697 4.58148 18.7753 4.92327 19.2928 5.37676C19.8104 5.83025 20.2287 6.38568 20.5217 7.00829C20.8147 7.6309 20.976 8.30728 20.9956 8.99511C21.0152 9.68293 20.8925 10.3674 20.6354 11.0056C20.3783 11.6439 19.9922 12.2222 19.5013 12.7044"
      stroke="#B6BEC7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.9998 6.12671L8.70685 9.41971C8.51938 9.60724 8.41406 9.86154 8.41406 10.1267C8.41406 10.3919 8.51938 10.6462 8.70685 10.8337L9.24985 11.3767C9.93985 12.0667 11.0598 12.0667 11.7498 11.3767L12.7498 10.3767C13.3466 9.77998 14.1559 9.44474 14.9998 9.44474C15.8438 9.44474 16.6531 9.77998 17.2498 10.3767L19.4998 12.6267"
      stroke="#B6BEC7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 15.6267L14.5 17.6267"
      stroke="#B6BEC7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 13.1267L17 15.1267"
      stroke="#B6BEC7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ClientCentricityIcon
