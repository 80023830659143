import React from 'react'

const InnovativenessIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    style={{ fill: 'none' }}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 13.1267C5.78309 13.3386 7.44305 14.1442 8.71276 15.414C9.98247 16.6837 10.7881 18.3436 11 20.1267C11.8839 19.6171 12.6233 18.8905 13.1482 18.0156C13.6732 17.1408 13.9663 16.1464 14 15.1267C15.6791 14.536 17.1454 13.4607 18.2133 12.0367C19.2813 10.6127 19.9031 8.90405 20 7.12671C20 6.33106 19.6839 5.568 19.1213 5.00539C18.5587 4.44278 17.7956 4.12671 17 4.12671C15.2227 4.22362 13.514 4.84537 12.09 5.91336C10.666 6.98135 9.59069 8.4476 9 10.1267C7.98026 10.1604 6.98596 10.4535 6.11106 10.9785C5.23617 11.5034 4.50959 12.2428 4 13.1267Z"
      stroke="#B6BEC7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.9995 14.1267C5.95873 14.7143 5.11716 15.5993 4.58266 16.6683C4.04816 17.7373 3.8451 18.9416 3.9995 20.1267C5.18466 20.2811 6.38893 20.0781 7.45793 19.5436C8.52692 19.0091 9.41193 18.1675 9.9995 17.1267"
      stroke="#B6BEC7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 9.12671C14 9.39193 14.1054 9.64628 14.2929 9.83382C14.4804 10.0214 14.7348 10.1267 15 10.1267C15.2652 10.1267 15.5196 10.0214 15.7071 9.83382C15.8946 9.64628 16 9.39193 16 9.12671C16 8.86149 15.8946 8.60714 15.7071 8.4196C15.5196 8.23207 15.2652 8.12671 15 8.12671C14.7348 8.12671 14.4804 8.23207 14.2929 8.4196C14.1054 8.60714 14 8.86149 14 9.12671Z"
      stroke="#B6BEC7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default InnovativenessIcon
