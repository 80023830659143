import React from 'react'

const ResponsibilityIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    style={{ fill: 'none' }}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 21.1267V19.1267C6 18.0658 6.42143 17.0484 7.17157 16.2983C7.92172 15.5481 8.93913 15.1267 10 15.1267H12"
      stroke="#B6BEC7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 16.1267C22 20.1267 19.5 22.1267 18.5 22.1267C17.5 22.1267 15 20.1267 15 16.1267C16 16.1267 17.5 15.6267 18.5 14.6267C19.5 15.6267 21 16.1267 22 16.1267Z"
      stroke="#B6BEC7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 7.12671C8 8.18757 8.42143 9.20499 9.17157 9.95514C9.92172 10.7053 10.9391 11.1267 12 11.1267C13.0609 11.1267 14.0783 10.7053 14.8284 9.95514C15.5786 9.20499 16 8.18757 16 7.12671C16 6.06584 15.5786 5.04843 14.8284 4.29828C14.0783 3.54814 13.0609 3.12671 12 3.12671C10.9391 3.12671 9.92172 3.54814 9.17157 4.29828C8.42143 5.04843 8 6.06584 8 7.12671Z"
      stroke="#B6BEC7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ResponsibilityIcon
