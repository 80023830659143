// extracted by mini-css-extract-plugin
export var section = "about-module--section--OEhzS";
export var section_careers = "about-module--section_careers---3P+2";
export var imageGrid = "about-module--imageGrid--18huQ";
export var imageCell = "about-module--imageCell--JXT8n";
export var cards = "about-module--cards--Gl79M";
export var card = "about-module--card--KnNBX";
export var name = "about-module--name--iprOr";
export var quote = "about-module--quote--FZBAB";
export var button = "about-module--button--pATQA";
export var description_block = "about-module--description_block--y3fFI";
export var image_block = "about-module--image_block--QEE4b";
export var our_values = "about-module--our_values--zc2f6";
export var our_value = "about-module--our_value--Tljjf";
export var iframe_content = "about-module--iframe_content--623TE";